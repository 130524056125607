import React, { useEffect, useRef } from 'react';

function injectScript(target) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src =
      '//www.myregistry.com//Visitors/GiftList/iFrames/EmbedRegistry.ashx?r=uRKmaF1MAjL5EtFh1FPREQ2&v=2';
    script.id = 'script_myregistry_giftlist_iframe';
    target.appendChild(script);

    script.onload = () => {
      resolve();
    };
  });
}

export default function Registry(props) {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    injectScript(ref.current);
  }, [ref.current]);

  return (
    <section data-theme="" id="registry">
      <br />
      <div ref={ref} style={{ height: '40em' }} />
    </section>
  );
}
